import React from 'react'
import styled from 'styled-components'
import logoBold from "../../assets/logoBold.png"
import breakpoints from '../styles/breakpoints'
import CurrentPathContext from './CurrentPathContext'

import { Link as GatsbyLink } from "gatsby"

const LogoImage = styled.div`
    background: url(${logoBold}) top left no-repeat;
    background-size:100% 100%;
    height:0;
    padding-top:50%;
    /* margin-bottom: ${props => props.$currentpathname !== '/' && '-8px'}; */

    @media screen and (${breakpoints.tabletLandscapeUp}){
        background: url(${logoBold}) top left no-repeat;
        margin-bottom: initial; 
        background-size:100% 100%;
    }
`

const LogoLink = styled(GatsbyLink)`
    margin: ${props => props.$currentPathName === '/' ? 'auto' : 'auto auto auto 0'};
    width:${props => props.$currentPathName === '/' ? '230px' : '200px'};

    @media(${breakpoints.tabletUp}){        
        width: ${props => props.$currentPathName === '/' ? '300px' : '250px'};
    };

    @media(${breakpoints.tabletLandscapeUp}){        
        margin: auto;
    };
`

const HeaderLogo = () => {

    const currentPathName = React.useContext(CurrentPathContext)
    
    return (
        <LogoLink to='/' $currentPathName={currentPathName}>
            <LogoImage $currentpathname={currentPathName} />
        </LogoLink>
    )
}

export default HeaderLogo
