import {css} from 'styled-components'
import typography from './typography'
import colors from './colors'
import breakpoints from './breakpoints'

const globalStyles = css`

html{overflow-x:hidden;}

    html,body {
        
        font-family: ${typography.fonts.primary};
        height:100%;
        color: ${colors.primary};
        letter-spacing: 0.5px;
        font-size:18px;

        @media screen and (${breakpoints.tabletUp}){
            font-size:1.3rem;
            letter-spacing: 1px;
        }
    }

    P{
        line-height: 1.1;

        @media screen and (${breakpoints.tabletLandscapeUp}){
            line-height: 1.4;
        }        
    }

    label{
        display:block;
        text-align:left;
        margin:0;
    }


    a.plain {
        color: black;
        text-decoration: none;
    }

    input:focus {outline: 0 !important; border: 0 !important; box-shadow: none !important;}

    ul {
        padding: 0.5rem 0rem;
    }

    h1,h2,h3,h4,h5{
        font-family: 'Alegreya Sans SC';
        letter-spacing: 0.1rem;
        margin: 0;
    }

    h3{
        font-size: 1.25rem;
    }

    button{
        border: unset;
        background-color: unset;
        &:focus{
            outline: none;
        }
    }
`
export default globalStyles