import React,{useContext} from 'react'
import Link from '../Link'
import styled from 'styled-components'
import MenuActiveContext from '../MenuActiveContext'
import colors from '../../styles/colors'

const OffCanvasNavLink = styled(Link)`
    text-transform: capitalize;
    text-decoration: none;
    color: ${props => props.$isActive ? colors.secondary : colors.primary};
`

const OffCanvasNavItem = ({ route, children }) => {
    const [, setIsMenuActive] = useContext(MenuActiveContext)
    const [isActive, setIsActive] = React.useState(false)
    
        return (
            <li>
                <OffCanvasNavLink $isActive={isActive} getProps={({ isCurrent }) => setIsActive(isCurrent)} to={route} onClick={() => setIsMenuActive(false)}>
                    <h2>{children}</h2>
                </OffCanvasNavLink>
            </li>
        )
}

export default OffCanvasNavItem
