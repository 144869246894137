import React from "react"
import MenuActiveContext from '../components/MenuActiveContext'


const MenuActiveProvider = ({ children }) => {

    const menuActiveState = React.useState(false)

    React.useEffect(() => {
        if (menuActiveState[0]) {
            document.body.style.overflow = 'hidden';
        }
        else {document.body.style.overflow = ''}
    },[menuActiveState])

    return (
        <MenuActiveContext.Provider value={menuActiveState}>
            {children}
        </MenuActiveContext.Provider>
    )
}

export default MenuActiveProvider

