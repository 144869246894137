import React from 'react';
import Link from './Link';
import styled from 'styled-components';

export const StyledImgBg = styled.div`
    width:100%;
    height:100%;
    background-image: url(${props => props.source});
    background-size: cover;
    background-repeat: no-repeat;
`;

const StyledLink = styled(Link)`
    display:flex;
    justify-content:center;
    

    img{
        width:100%;
        height:auto;
    }
`

const Img = ({ src = "", linkTo, alt = "", asBackground = false, className, target, ...rest }) => {

    return (
        <StyledLink to={linkTo} target={target} className={className}>
            {
                asBackground
                    ?
                    <StyledImgBg className="img" source={src} />
                    :
                    <img className='img' alt={alt || ''} src={src} {...rest} />
            }
        </StyledLink>
    );
};

export default Img;
