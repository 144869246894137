import React from "react"
import {window} from "browser-monads"

const BreakpointContext = React.createContext({})

const BreakpointProvider = ({ children }) => {
    const breakpointQueries = {
        tabletUp: window.matchMedia("(min-width: 600px)"),
        tabletLandscapeUp: window.matchMedia("(min-width: 980px)"),
        pcSmallUp: window.matchMedia("(min-width: 1199px)"),
        // pcLarge: window.matchMedia("(min-width: 1800px)")
    }

    const [breakpoint, setBreakpoint] = React.useState(() => {
        const breakpoints = {}
        Object.entries(breakpointQueries).forEach(([breakpoint, query]) => {
            breakpoints[breakpoint] = query.matches
        })
        return breakpoints
    })

    function handleBreakpointChange() {
        const breakpoints = {}

        Object.entries(breakpointQueries).forEach(([breakpoint, query]) => {
            breakpoints[breakpoint] = query.matches
        })

        setBreakpoint(breakpoints)
    }

    React.useEffect(() => {
        for (let query of Object.values(breakpointQueries)) {
            query.addListener(handleBreakpointChange)
        }

        return () => { 
            for (let query of Object.values(breakpointQueries)) {
                query.removeListener(handleBreakpointChange)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <BreakpointContext.Provider value={breakpoint}>
            {children}
        </BreakpointContext.Provider>
    )
}

export default BreakpointContext
export { BreakpointProvider }
