import { Link as GatsbyLink } from "gatsby"
import React from 'react'

const Link = ({ children, to, className, activeClassName, partiallyActive, getProps,...rest }) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)
    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
        return (
            <GatsbyLink
                to={to}
                className={className}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                getProps={getProps}
                {...rest}
            >
                {children}
            </GatsbyLink>
        )
    }
    return (
        <a href={to} className={className} {...rest}>
            {children}
        </a>
    )
}
export default Link