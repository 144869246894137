import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import HeaderLogo from './HeaderLogo'

import OffCanvasNav from "./offCanvasNav/OffCanvasNav"
import Burger from "./Burger"
import Nav from "./nav/Nav"
import breakpoints from '../styles/breakpoints'
import CurrentPathContext from './CurrentPathContext'

const StyledHeader = styled.header`
    display: flex;
    width:100%;
    margin-top:${props => props.$currentpathname === '/' && 'auto'};
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 0 0;
    justify-content:center;
`

const Separator = styled.hr`
    display:block;
    width: 100%;
    margin: ${props => props.$currentpathname === '/' ? '0.6rem' : '0.6rem 0 0.5rem 0'};

    @media(${breakpoints.tabletLandscapeUp}){
        margin: ${props => props.$currentpathname === '/' ? '1rem 0 0.8rem 0' : '0.6rem 0 0.5rem 0'};
    }

    border: 0;
    border-top: 1px solid black;
`

const Header = ({ className }) => {

    const currentPathName = React.useContext(CurrentPathContext)

    const metadataQuery = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        menuItems {
                            name
                            link
                        }
                    }
                }
            }
        `
    )

    const menuItems = metadataQuery.site.siteMetadata.menuItems

    return (
        <StyledHeader $currentpathname={currentPathName} className={className}>
            <HeaderLogo currentPathName={currentPathName}/>
            <Separator $currentpathname={currentPathName} />
            <Nav socials={currentPathName !== '/'} $currentpathname={currentPathName}>
                {menuItems.map(page => (
                    <Nav.Item key={page.name} route={page.link}>
                        {page.name}
                    </Nav.Item>
                ))}
            </Nav>
            <Burger />

            <OffCanvasNav>
                {menuItems.map(page => (
                    <OffCanvasNav.Item
                        key={page.name}
                        route={page.link}
                    >
                        {page.name}
                    </OffCanvasNav.Item>
                ))}
            </OffCanvasNav>
        </StyledHeader>
    )
}

export default Header
