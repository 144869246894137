import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import MenuActiveContext from './MenuActiveContext'
import CurrentPathContext from './CurrentPathContext';
import breakpoints from '../styles/breakpoints'

const StyledBurger = styled.div`
    align-self:flex-end;
    top: ${props=>props.$currentPathName === '/' ? '0' : '90px'};
    z-index: 799;
    display: block;
    @media screen and (${breakpoints.tabletLandscapeUp}){
        display:none;
    }
    @media screen and (${breakpoints.tabletUp}){
        top: ${props=>props.$currentPathName === '/' ? '0' : '115px'};
    }
    /* transition: 0.5s; */
    position:absolute;

    ${props => (
        props.$active && css`
            ${BurgerSlice}{
                &:nth-child(2) {
                    transform: translate(100%);
                    opacity: 0;
                };
                &:last-child {
                    transform: rotate(-45deg) translate(6px, -6px);
                };

                &:first-child {
                    transform: rotate(45deg) translate(7px, 6px);
                };
            };
        `
    )};  
`

const BurgerSlice = styled.span`
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background-color: ${colors.primary};
    border-radius: 3px;
    transition: transform 0.4s,
    background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
`

function Burger(props) {

    const currentPathName = React.useContext(CurrentPathContext)

    const [isMenuActive, setIsMenuActive] = useContext(MenuActiveContext)

    return (
        <StyledBurger
            $currentPathName={currentPathName}
            onClick={() => setIsMenuActive(!isMenuActive)}
            $active={isMenuActive}
            {...props}
        >
            <BurgerSlice></BurgerSlice>
            <BurgerSlice></BurgerSlice>
            <BurgerSlice></BurgerSlice>
        </StyledBurger>
    )
}

export default Burger
