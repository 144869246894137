import React, { useContext } from 'react'
import OffCanvasNavItem from './OffCanvasNavItem'
import styled, {css} from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import Socials from '../Socials'
import MenuActiveContext from '../MenuActiveContext'

const Content = styled.div`
    display: flex;
    margin-left:auto;
    z-index:798;
    height: 100vh;
    width: 80%;
    box-shadow: 12px -6px 20px 1px black;

    @media(${breakpoints.tabletUp}){
        width: 60%;
    }

    background-color: white;
`

const Container = styled.div`
    display:block;
    z-index:700;

    @media screen and (${breakpoints.tabletLandscapeUp}){
        display:none;
    }

    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;

    background-color: transparent;
    transition: transform 0.5s ease;

    ${props => props.active && css`
        html, body{overflow: hidden}
    `}

    transform: ${props => !props.active ? 'translateX(100%)' : 'translateX(0)'};
`

const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 80%;
    gap:3rem;
    align-items: center;
    justify-content: center;
    list-style: none;
`

const Nav = styled.nav`
    margin:auto;
    height:90%;
    width:100%;
`


const OffCanvasNav = ({ children }) => {
    const [active, setActive] = useContext(MenuActiveContext)

    const close = (e) => {
        if (e) {
            //stop react synthetic event propagation
            e.stopPropagation();
            // stop native document event propagation
            e.nativeEvent.stopImmediatePropagation();
            //trigger only if the target is the one the handler is defined on
            (e.target === e.currentTarget) && setActive(false);
        }
    }
    return (
        <Container active={active} onClick={close}>
            <Content>
                <Nav>
                    <NavList>{children}</NavList>
                    <Socials />
                </Nav>
            </Content>
        </Container>
    )
}

OffCanvasNav.Item = OffCanvasNavItem;
export default OffCanvasNav
