import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Socials from "../components/Socials"
import CurrentPathContext from './CurrentPathContext'

const StyledFooter = styled.footer`
    font-family: arial;
    margin-top:auto;
    grid-area: footer;
`

const StyledSocials = styled(Socials)`
    margin: 0 auto 0 auto;
    img {
        max-width: 50px;
    }
`

const Footer = () => {
    // const queryData = useStaticQuery(graphql`
    //     query {
    //         site {
    //             siteMetadata {
    //                 author
    //             }
    //         }
    //     }
    // `)
    const currentPathName = React.useContext(CurrentPathContext)

    return (
        <StyledFooter>
            {currentPathName === '/' && <StyledSocials />}
            {/* <p>
                Created by {queryData.site.siteMetadata.author}, copyright 2020
            </p> */}
        </StyledFooter>
    )
}

export default Footer
