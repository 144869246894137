import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import styled from 'styled-components'
import breakpoints from '../styles/breakpoints'
import CurrentPathContext from '../components/CurrentPathContext'
import canvasTexture from '../../assets/canvasTexture.jpg'
import Seo from './Seo'


export const LayoutContainer = styled.div`
	margin: 0 auto;
	position: relative;
	display: flex;
	flex-direction:column;
	justify-content:center;
	height: ${props => (props.currentPathName === '/' ? '90vh' : '100%')};
	min-height: ${props => (props.currentPathName !== '/' && '100vh')};	

	@media (orientation:landscape) and (${breakpoints.tabletLandscapeDown}){
		height:100%;
		min-height:90vh;
		};

	padding: 0 1rem 2rem 1rem;

	@media screen and (${breakpoints.tabletUp}) {
		padding: 0 5% 2rem 5%;
	}
	
	/* Special case for small screen/high resolution laptops  */
	@media screen and (${breakpoints.mediumDpiUp}) and (${breakpoints.pcSmallUp}) and (${breakpoints.pcLargeDown}){
		width: ${props => (props.currentPathName === '/' ? '80%' : '100%')};
	}
	width:100%;
	max-width: 1400px;
`

const MasterContainer = styled.div`
	display:flex;
	align-items:center;
	width:100vw;
	min-height: 100vh;
	height:100%;
	max-width:100%;
`

const MainContent = styled.div`
	/* flex-grow:1; */
	width:100%;
    display: flex;
    flex-direction: column;
    position:relative;
    justify-content: center;
	margin: 1rem 0 0 0;
`

const BackgroundImage = styled.div`
    position: fixed;
    top: 0;
    z-index: -999;
    opacity: 0.5;
    min-width:100vw;
	min-height: 100vh;
	height: 100vh;
    background-image: url(${canvasTexture});
    background-size: cover;
`

const Layout = ({ children, className, currentPathName }) => {
	return (
		<MasterContainer translate="no">
			<Seo />
			<BackgroundImage />
			<CurrentPathContext.Provider value={currentPathName}>
				<LayoutContainer currentPathName={currentPathName} className={className}>
					<Header />
					<MainContent>
						{children}
					</MainContent>
					<Footer />
				</LayoutContainer>
			</CurrentPathContext.Provider>
		</MasterContainer>
	)
}

export default Layout
