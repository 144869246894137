import React from "react"
import { BreakpointProvider } from "./src/providers/BreakpointContext"
import GlobalStylesProvider from "./src/providers/GlobalStyleProvider"
import StylesNormalizeProvider from "./src/providers/StylesNormalizeProvider"
import MenuActiveProvider from "./src/providers/MenuActiveProvider"
import "typeface-alegreya-sans"
import "typeface-alegreya-sans-sc"
import Layout from "./src/components/Layout"

export const wrapRootElement = ({ element, props }) => (
    <>
        <GlobalStylesProvider />
        <StylesNormalizeProvider />
        <MenuActiveProvider>
            <BreakpointProvider>{element}</BreakpointProvider>
        </MenuActiveProvider>
    </>
)

export const wrapPageElement = ({ element, props }) => {
    return (
        <Layout currentPathName={props.location.pathname}>{element}</Layout>
    )
}