const colors = {
    primary: "#373E42",
    primaryLight: "#76848c",
    transparent: "rgba(0,0,0,0)",
    secondary: "#798185",
    secondaryLight: "#929ca1",
    black: "#000000",
    lightGrey: "#C4C4C4",
    danger: "#721c24",
    success: "#155724",
    dangerLight: "#f8d7da",
    successLight: "#d4edda"
}

export default colors