import React from 'react'
import styled from 'styled-components'
import instagramIcon from "../../assets/instagram-icon.svg";
import facebookIcon from "../../assets/facebook-icon.svg";
import Img from './Img'
import breakpoints from '../styles/breakpoints'

const SocialsPanel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const SocialIcon = styled(Img)`
    height: ${ props => props.$iconHeight || '35px'};
    width: ${ props => props.$iconWidth || '35px'};
    transition: 0.3s ease-out;
    background-size: contain;

    opacity: 0.6;

    &:hover{
        opacity: 0.8;
        transition: 0.3s ease-out;
    };

    @media(${breakpoints.tabletLandscapeUp}){
        height: ${ props => props.$iconHeight || '40px'};
        width: ${ props => props.$iconWidth || '40px'};
    }
`

const Socials = ({ socials, className, iconHeight, iconWidth }) => {

    const defaultSocials = [
        {
            url: "https://www.facebook.com/melania.kosmopoulou",
            icon: facebookIcon,
            name: "facebook",
        },
        {
            url: "https://www.instagram.com/melaniakosmopoulou/",
            icon: instagramIcon,
            name: "instagram",
        },
    ];

    const socialsToRender = socials || defaultSocials;

    return (
        <SocialsPanel className={className}>
            {socialsToRender.map(social => (
                <SocialIcon $iconHeight={iconHeight} $iconWidth={iconWidth} key={social.name} linkTo={social.url} target='_blank' src={social.icon} asBackground={true} />
            ))}
        </SocialsPanel>
    )
}

export default Socials
