const breakpoints = {
    tabletUp:  "min-width: 600px",
    tabletLandscapeUp: "min-width: 980px",
    pcSmallUp:  "min-width: 1200px",
    pcLargeUp: "min-width: 1800px",
    tabletDown:  "max-width: 599px",
    tabletLandscapeDown: "max-width: 979px",
    pcSmallDown:  "max-width: 1199px",
    pcLargeDown: "max-width: 1799px",
    mediumDpiUp: "min-resolution:90dpi"
}

export default breakpoints
