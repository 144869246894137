import React from 'react'
import NavItem from './NavItem'
import Socials from '../Socials'
import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'

const StyledNav = styled.div`
    position:relative;
    padding: 0 1rem;
    display: none;
    @media screen and (${breakpoints.tabletLandscapeUp}){
        display:flex;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
`

const NavItemList = styled.ul`
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 0; 
        list-style-type: none;
        align-items:center;
`

const NavSocials = styled(Socials)`
    position:absolute;
    right:0;    
`

const Nav = ({ children, className, socials }) => {

    return (
        <StyledNav className={className}>
            <NavItemList>
                {children}
            </NavItemList>
            {socials && <NavSocials iconHeight='30px' iconWidth='30px'/>}
        </StyledNav>
    )
}
Nav.Item = NavItem;

export default Nav;
