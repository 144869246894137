import React from 'react'
import Link from '../Link'
import styled from 'styled-components'
import colors from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const NavItemLink = styled(Link)`
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1px;
            color: ${props => props.$isActive ? colors.secondary : colors.primary};
            font-size: 1rem;
            text-decoration: none;
            transition: color 0.4s linear, border-color 0.3s linear;

            @media(${breakpoints.tabletLandscapeUp}){
                font-size: 1.2rem;
            }
            
            &:hover{
                transition: color 0.1s ease-in;
                border-bottom-color: ${props=>!props.$isActive && colors.secondary};
                transition: border-bottom-color 0.3s linear;
            };

            border-bottom-color:${props=>props.$isActive?'black':'transparent'};
            border-bottom-width: 1px;
            border-bottom-style: solid;
            /* Increased margin since the box-shado expands outside the element, like outline */
            padding-bottom: 0.6rem;
`

export const NavListItem = styled.li`
    &:not(:last-child) {
        margin-right: 5rem;
    }
`

function NavItem({ route, children, className }) {

    const [isActive, setIsActive] = React.useState(false);

    return (
        <NavListItem className={className}>
            <NavItemLink
                $isActive={isActive}
                to={route}
                //get active state, update local state and pass it back in as a custom prop to be used in the styled-component
                getProps={({ isCurrent }) => setIsActive(isCurrent)}
            >
                {children}
            </NavItemLink>
        </NavListItem>
    )
}

export default NavItem
